import { FC } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'

import styles from './FurtherQuestions.module.css'
import ThreeByThreeDots from '../ThreeByThreeDots/ThreeByThreeDots'
import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'

type Props = {
  className?: string
  style?: object
}

const FurtherQuestions: FC<Props> = ({ className, style }) => {
  const t = useTranslations('index.footerSection')

  return (
    <article className={classNames(className, styles.component)} style={style}>
      <div className={styles.dotsContainer}>
        <ThreeByThreeDots className={styles.dots} />
      </div>
      <div className={styles.label}>{t('contact')}</div>
      <div className={styles.furtherQuestions}>{t('further-questions')}</div>
      <div className={styles.askForMoreContainer}>
        <p className={styles.askForMore}>{t('ask-for-further-info')}</p>
      </div>
      <div className={styles.buttoncontainer}>
        <Link href="/contact-us">
          <a>
            <SeparatedIconButton
              className={styles.interestedButton}
              label={t('interested-button-label')}
            />
          </a>
        </Link>
      </div>
    </article>
  )
}

export default FurtherQuestions
