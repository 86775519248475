import classNames from 'classnames'
import { FC } from 'react'

import styles from './ContactInfo.module.scss'

type ContactInfoProps = {
  className?: string
  text: string
  icon: JSX.Element
}

const ContactInfo: FC<ContactInfoProps> = ({ className, text, icon }) => {
  return (
    <div className={classNames(className, styles.component)}>
      <div className={styles.icon}>{icon}</div>
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default ContactInfo
