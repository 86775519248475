import { FC } from 'react'

type Props = {
  className?: string
}

const BlueRedBoxes: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 1073 887" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-182" y="284.408" width="1175" height="965.812" rx="80" transform="rotate(-5 -182 284.408)" fill="#D63615"/>
      <rect x="-281" y="102.408" width="1175" height="965.812" rx="80" transform="rotate(-5 -281 102.408)" fill="#29367A"/>
    </svg>

  )
}

export default BlueRedBoxes

