import { FC } from 'react'

type Props = {
  className?: string
}

const BoxyLogo: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 120 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M34.4547 23.1758H39.8409C40.2323 23.1758 40.5497 23.4957 40.5497 23.8901V36.1837C40.5497 36.5782 40.2323 36.898 39.8409 36.898H34.4547C34.0633 36.898 33.7461 36.5782 33.7461 36.1837V23.8901C33.7461 23.4957 34.0633 23.1758 34.4547 23.1758Z"
        fill="#FBAA1A"
      />
      <path
        d="M33.0368 16.3141V21.7434C33.0368 22.1379 32.7194 22.4577 32.3281 22.4577H20.1295C19.7383 22.4577 19.4209 22.1379 19.4209 21.7434V16.3141C19.4209 15.9196 19.7383 15.5996 20.1295 15.5996H32.3281C32.7194 15.5996 33.0368 15.9196 33.0368 16.3141Z"
        fill="#A3CD3B"
      />
      <path
        d="M12.6131 23.1758H17.9989C18.3905 23.1758 18.7079 23.4957 18.7079 23.8905V36.1853C18.7079 36.5798 18.3905 36.8996 17.9991 36.8996H12.6131C12.2217 36.8996 11.9043 36.5796 11.9043 36.1851V23.8905C11.9043 23.4957 12.2217 23.1758 12.6131 23.1758Z"
        fill="#00B9F0"
      />
      <path
        d="M33.0366 38.3307V43.76C33.0366 44.1545 32.7194 44.4745 32.3279 44.4745H20.1295C19.7381 44.4745 19.4209 44.1545 19.4209 43.76V38.3307C19.4209 37.9362 19.7381 37.6162 20.1295 37.6162H32.3279C32.7194 37.6162 33.0366 37.9362 33.0366 38.3307Z"
        fill="#EF4325"
      />
      <path
        d="M20.1328 30.0367C20.1328 26.644 22.8614 23.8936 26.2272 23.8936C29.5932 23.8936 32.3218 26.644 32.3218 30.0367C32.3218 33.4297 29.5932 36.1801 26.2272 36.1801C22.8614 36.1801 20.1328 33.4297 20.1328 30.0367Z"
        fill="#22407F"
      />
      <path
        d="M54.0224 32.3287C55.2574 32.3287 56.1841 31.6739 56.1841 30.5132C56.1841 29.0843 55.0892 28.5783 53.6571 28.5783H50.2041V32.3287H54.0224ZM53.6571 24.5898C54.5558 24.5898 55.3698 24.1731 55.3698 23.1908C55.3698 22.1192 54.4154 21.5835 53.4605 21.5835H50.2041V24.5898H53.6571ZM54.5558 17.0293C57.279 17.0293 59.9461 18.6067 59.9461 22.0299C59.9461 23.9349 59.1881 25.5124 58.065 26.3162C59.7215 27.0305 60.7884 28.995 60.7884 31.4061C60.7884 34.8293 58.1774 36.8831 55.1172 36.8831H45.375V17.0293H54.5558Z"
        fill="#22407F"
      />
      <path
        d="M66.1427 29.5624C66.1427 31.3482 67.5746 32.8368 69.2311 32.8368C71.084 32.8368 72.5439 31.3482 72.5439 29.5624C72.5439 27.7765 71.084 26.2882 69.2311 26.2882C67.5746 26.2882 66.1427 27.7765 66.1427 29.5624ZM77.2044 29.5624C77.2044 33.7595 73.667 37.1527 69.2311 37.1527C64.9076 37.1527 61.4824 33.7595 61.4824 29.5624C61.4824 25.3656 64.9076 21.9424 69.2311 21.9424C73.667 21.9424 77.2044 25.3656 77.2044 29.5624Z"
        fill="#22407F"
      />
      <path
        d="M81.375 29.5607C79.7183 27.1794 77.9778 24.5899 76.3213 22.2383H81.7118L84.07 25.6316C84.8 24.5601 85.7265 23.2802 86.4283 22.2383H91.8469L86.7372 29.5607L91.8469 36.8832H86.4283L84.07 33.4899C83.3681 34.5615 82.4137 35.8712 81.7118 36.8832H76.3213L81.375 29.5607Z"
        fill="#22407E"
      />
      <path
        d="M99.3427 30.1264L102.122 22.2383H107.119L102.515 34.8889L102.234 35.8117C101.392 38.4014 99.1458 42.8066 94.7382 42.7173C94.4575 42.7173 93.9801 42.6875 93.4467 42.5984V38.2822C93.9521 38.3716 94.4013 38.4014 94.6539 38.4014C95.9174 38.4014 97.0123 37.4189 97.0683 36.2583L91.5938 22.2383H96.5913L99.3427 30.1264Z"
        fill="#22407F"
      />
    </svg>
  )
}

export default BoxyLogo
