import { FC } from 'react'

interface Props {
  className?: string
}

const ArrowRightIcon: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" className={className}>
      <path
        d="M2 7.25C1.58579 7.25 1.25 7.58579 1.25 8C1.25 8.41421 1.58579 8.75 2 8.75L2 7.25ZM14.5303 8.53033C14.8232 8.23744 14.8232 7.76256 14.5303 7.46967L9.75736 2.6967C9.46447 2.40381 8.98959 2.40381 8.6967 2.6967C8.40381 2.98959 8.40381 3.46447 8.6967 3.75736L12.9393 8L8.6967 12.2426C8.40381 12.5355 8.40381 13.0104 8.6967 13.3033C8.98959 13.5962 9.46447 13.5962 9.75736 13.3033L14.5303 8.53033ZM2 8.75L14 8.75V7.25L2 7.25L2 8.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowRightIcon
