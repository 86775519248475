import { FC } from 'react'

type Props = {
  className?: string
}

const MailIcon: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M1.26316 3.5C0.570539 3.5 0 4.0646 0 4.75V12.25C0 12.9354 0.570539 13.5 1.26316 13.5H14.7368C15.4295 13.5 16 12.9354 16 12.25V4.75C16 4.0646 15.4295 3.5 14.7368 3.5H1.26316ZM1.4375 4.33333H14.5625L10.699 8.15658C10.6616 8.18312 10.6288 8.21556 10.602 8.2526L9.19079 9.64909C8.52931 10.3037 7.47069 10.3037 6.80921 9.64909L5.39967 8.25423C5.37285 8.21719 5.34007 8.18475 5.30263 8.1582L1.4375 4.33333ZM0.842105 4.92253L4.45724 8.5L0.842105 12.0775V4.92253ZM15.1579 4.92253V12.0775L11.5428 8.5L15.1579 4.92253ZM5.05263 9.08919L6.21382 10.2383C7.19696 11.2112 8.80304 11.2112 9.78618 10.2383L10.9474 9.08919L14.5625 12.6667H1.4375L5.05263 9.08919Z" fill="currentColor"/>
    </svg>
  )
}

export default MailIcon