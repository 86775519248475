import { FC } from 'react'
import classNames from 'classnames'
import useTranslations from '../I18nProvider/I18nProvider'

import styles from './BoxyContact.module.scss'
import BoxyLogoWhite from '../images/BoxyLogoWhite'
import ContactInfo from '../ContactInfo/ContactInfo'
import LocationIcon from '../icons/LocationIcon'
import MailIcon from '../icons/MailIcon'
import BlueRedBoxes from '../images/BlueRedBoxes'

type BoxyContactProps = {
  className?: string
}

const BoxyContact: FC<BoxyContactProps> = ({ className }) => {
  const t = useTranslations('index.footerSection')

  return (
    <div className={classNames(styles.component, className)}>
      <div className={styles.boxes}>
        <BlueRedBoxes />
      </div>
      <div className={styles.content}>
        <div className={styles.boxylogowhite}>
          <BoxyLogoWhite />
        </div>
        <p className={classNames(styles.boldtext, styles.contact)}>{t('contact')}</p>
        <a href={`mailto:${t('email-address')}`}>
          <ContactInfo icon={<MailIcon />} text={t('email-address')}></ContactInfo>
        </a>
        <ContactInfo icon={<LocationIcon />} text={t('address')}></ContactInfo>
        <div className={styles.separatorline}></div>
        <p className={styles.allrightsreserved}>{t('all-rights-reserved')}</p>
      </div>
    </div>
  )
}

export default BoxyContact
