import { FC } from 'react'
import classNames from 'classnames'

import styles from './IndexFooterSection.module.scss'
import BoxyContact from './BoxyContact'
import FurtherQuestions from './FurtherQuestions'

type Props = {
  className?: string
  style?: object
}

const IndexFooterSection: FC<Props> = ({ className, style }) => {
  return (
    <div className={classNames(styles.component, className)} style={style}>
      <FurtherQuestions className={styles.furtherQuestions} />
      <BoxyContact className={styles.boxyContact} />
    </div>
  )
}

export default IndexFooterSection
