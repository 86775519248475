import useTranslations from '../I18nProvider/I18nProvider'
import { FC } from 'react'
import styles from './SzechenyiLogo.module.scss'

const SzechenyiLogo: FC = () => {
  const t = useTranslations('header')

  return (
    <div className={styles.szechenyi}>
      <img src={`/images/szechenyi/${t('logo-src')}`} alt="Szechenyi logo" />
    </div>
  )
}

export default SzechenyiLogo
