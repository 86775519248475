import { CSSProperties, FC } from 'react'

type Props = {
  className?: string
  style?: CSSProperties
}

const ThreeByThreeDots: FC<Props> = ({ className, style }) => {
  return (
    <svg
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <circle cx="4" cy="4" r="4" fill="#CECECE" />
      <circle cx="4" cy="36" r="4" fill="#CECECE" />
      <circle cx="4" cy="68" r="4" fill="#CECECE" />
      <circle cx="36" cy="4" r="4" fill="#CECECE" />
      <circle cx="36" cy="36" r="4" fill="#B4C822" />
      <circle cx="36" cy="68" r="4" fill="#48B1E9" />
      <circle cx="68" cy="4" r="4" fill="#CECECE" />
      <circle cx="68" cy="36" r="4" fill="#F4A900" />
      <circle cx="68" cy="68" r="4" fill="#D63615" />
    </svg>
  )
}

export default ThreeByThreeDots
