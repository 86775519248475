import classNames from 'classnames'
import { FC, useState, forwardRef } from 'react'

import Link from 'next/link'
import BoxyLogo from '../images/BoxyLogo'
import styles from './Header.module.css'
import useTranslations from '../I18nProvider/I18nProvider'

type Props = {
  parentRef?: any
}

const Header: FC<Props> = ({ parentRef }) => {
  const t = useTranslations('header')
  const [isOpen, setIsOpen] = useState(false)

  const closeMenu = () => {
    setIsOpen(false)
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <header className={classNames(styles.header, isOpen && styles.open)} ref={parentRef}>
      <Link href="/">
        <a>
          <div className={styles.logo}>
            <BoxyLogo />
          </div>
        </a>
      </Link>
      <button className={styles.menuButton} aria-label="menu" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </button>
      <nav className={styles.nav}>
        <ul>
          <li>
            <Link href="/#introduction">
              <a onClick={closeMenu}>{t('nav.introduction')}</a>
            </Link>
          </li>
          <li>
            <Link href="/#services">
              <a onClick={closeMenu}>{t('nav.services')}</a>
            </Link>
          </li>
          <li>
            <Link href="/#integration">
              <a onClick={closeMenu}>{t('nav.integration')}</a>
            </Link>
          </li>
          <li>
            <Link href="/#our-team">
              <a onClick={closeMenu}>{t('nav.our-team')}</a>
            </Link>
          </li>
          <li>
            <Link href="/contact-us">
              <a onClick={closeMenu}>{t('nav.contact-us')}</a>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

const HeaderWithRef = forwardRef(function HeaderWithRef(props, ref) {
  return <Header {...props} parentRef={ref} />
})

export default HeaderWithRef
