import { FC } from 'react'

type Props = {
  className?: string
}

const BoxyLogoWhite: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.894 38.7949H66.945C67.6027 38.7949 68.1361 39.3448 68.1361 40.0226V61.1509C68.1361 61.829 67.6027 62.3785 66.945 62.3785H57.894C57.2362 62.3785 56.7031 61.829 56.7031 61.1509V40.0226C56.7031 39.3448 57.2362 38.7949 57.894 38.7949Z" fill="currentColor"/>
      <path d="M55.5103 27.0014V36.3324C55.5103 37.0105 54.9769 37.56 54.3195 37.56H33.8207C33.1633 37.56 32.6299 37.0105 32.6299 36.3324V27.0014C32.6299 26.3233 33.1633 25.7734 33.8207 25.7734H54.3195C54.9769 25.7734 55.5103 26.3233 55.5103 27.0014Z" fill="currentColor"/>
      <path d="M21.1912 38.7949H30.2415C30.8996 38.7949 31.433 39.3448 31.433 40.0233V61.1536C31.433 61.8317 30.8996 62.3813 30.2418 62.3813H21.1912C20.5334 62.3813 20 61.8314 20 61.1533V40.0233C20 39.3448 20.5334 38.7949 21.1912 38.7949Z" fill="currentColor"/>
      <path d="M55.51 64.8393V74.1703C55.51 74.8484 54.9769 75.3983 54.3192 75.3983H33.8207C33.1629 75.3983 32.6299 74.8484 32.6299 74.1703V64.8393C32.6299 64.1612 33.1629 63.6113 33.8207 63.6113H54.3192C54.9769 63.6113 55.51 64.1612 55.51 64.8393Z" fill="currentColor"/>
      <path d="M33.8271 50.5852C33.8271 44.7543 38.4123 40.0273 44.0683 40.0273C49.7247 40.0273 54.3098 44.7543 54.3098 50.5852C54.3098 56.4165 49.7247 61.1435 44.0683 61.1435C38.4123 61.1435 33.8271 56.4165 33.8271 50.5852Z" fill="currentColor"/>
      <path d="M90.7764 54.5247C92.8518 54.5247 94.409 53.3993 94.409 51.4045C94.409 48.9488 92.5691 48.0791 90.1626 48.0791H84.3601V54.5247H90.7764ZM90.1626 41.2243C91.6728 41.2243 93.0407 40.5081 93.0407 38.8199C93.0407 36.9782 91.4368 36.0576 89.8322 36.0576H84.3601V41.2243H90.1626ZM91.6727 28.2305C96.2488 28.2305 100.731 30.9415 100.731 36.8247C100.731 40.0988 99.457 42.8099 97.5697 44.1914C100.353 45.419 102.146 48.7953 102.146 52.9392C102.146 58.8224 97.7586 62.3522 92.6162 62.3522H76.2451V28.2305H91.6727Z" fill="currentColor"/>
      <path d="M111.144 49.7699C111.144 52.8392 113.55 55.3974 116.334 55.3974C119.447 55.3974 121.901 52.8392 121.901 49.7699C121.901 46.7005 119.447 44.1427 116.334 44.1427C113.55 44.1427 111.144 46.7005 111.144 49.7699ZM129.732 49.7699C129.732 56.9833 123.788 62.815 116.334 62.815C109.068 62.815 103.312 56.9833 103.312 49.7699C103.312 42.5571 109.068 36.6738 116.334 36.6738C123.788 36.6738 129.732 42.5571 129.732 49.7699Z" fill="currentColor"/>
      <path d="M136.738 49.7682C133.954 45.6757 131.029 41.2252 128.245 37.1836H137.304L141.266 43.0156C142.493 41.1739 144.05 38.9743 145.229 37.1836H154.335L145.748 49.7682L154.335 62.3531H145.229L141.266 56.5212C140.087 58.3628 138.483 60.6137 137.304 62.3531H128.245L136.738 49.7682Z" fill="currentColor"/>
      <path d="M166.933 50.7405L171.603 37.1836H180L172.264 58.9255L171.792 60.5115C170.377 64.9623 166.602 72.5334 159.195 72.3799C158.724 72.3799 157.921 72.3286 157.025 72.1754V64.7575C157.874 64.911 158.629 64.9623 159.054 64.9623C161.177 64.9623 163.017 63.2738 163.111 61.279L153.911 37.1836H162.309L166.933 50.7405Z" fill="currentColor"/>
    </svg>
  )
}

export default BoxyLogoWhite


