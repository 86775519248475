import classNames from 'classnames'
import { FC } from 'react'
import ArrowRightIcon from '../../icons/ArrowRightIcon'
import styles from './SeparatedIconButton.module.css'

type Props = {
  className?: string
  disabled?: boolean
  label: string
}

const SeparatedIconButton: FC<Props> = ({ className, disabled, label }) => {
  return (
    <button className={classNames(styles.button, className)} disabled={disabled}>
      <div className={styles.iconContainer}>
        <div className={styles.arrow}>
          <ArrowRightIcon />
        </div>
      </div>
      <div className={styles.label}>{label}</div>
    </button>
  )
}

export default SeparatedIconButton
