import { FC } from 'react'

type Props = {
  className?: string
}

const LocationIcon: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M8 1C5.24471 1 3 3.24471 3 6C3 6.77216 3.31455 7.6432 3.75 8.58594C4.18545 9.52868 4.75498 10.5273 5.32422 11.4453C6.46269 13.2814 7.59961 14.7988 7.59961 14.7988C7.64617 14.8611 7.70661 14.9116 7.77611 14.9464C7.84561 14.9812 7.92227 14.9993 8 14.9993C8.07773 14.9993 8.15439 14.9812 8.22389 14.9464C8.29339 14.9116 8.35383 14.8611 8.40039 14.7988C8.40039 14.7988 9.53731 13.2814 10.6758 11.4453C11.245 10.5273 11.8146 9.52868 12.25 8.58594C12.6854 7.6432 13 6.77216 13 6C13 3.24471 10.7553 1 8 1ZM8 2C10.2147 2 12 3.78529 12 6C12 6.47034 11.7521 7.28204 11.3438 8.16602C10.9354 9.04999 10.38 10.0216 9.82422 10.918C8.91295 12.3876 8.29935 13.2009 8 13.6094C7.70065 13.2009 7.08705 12.3876 6.17578 10.918C5.62002 10.0216 5.06455 9.04999 4.65625 8.16602C4.24795 7.28204 4 6.47034 4 6C4 3.78529 5.78529 2 8 2ZM8 5C7.73478 5 7.48043 5.10536 7.29289 5.29289C7.10536 5.48043 7 5.73478 7 6C7 6.26522 7.10536 6.51957 7.29289 6.70711C7.48043 6.89464 7.73478 7 8 7C8.26522 7 8.51957 6.89464 8.70711 6.70711C8.89464 6.51957 9 6.26522 9 6C9 5.73478 8.89464 5.48043 8.70711 5.29289C8.51957 5.10536 8.26522 5 8 5Z" fill="currentColor"/>
    </svg>
  )
}

export default LocationIcon